body {
    background-color: #202B37;
}

.mode-select {
    text-align: right;
    flex-grow: 1;
}

.ant-segmented-item {
    background-color: #000;
    color: #B0B3B8;
    border: 1px solid #B0B3B8;
}

.ant-segmented-item:hover {
    background-color: #000;
    color: #B0B3B8;
    border: 1px solid #B0B3B8;
}

.ant-segmented-item.ant-segmented-item-selected {
    background-color: #B0B3B8;
    color: #000;
}

.landscape-loading {
    width: 100%;
}
.login-page {
    text-align: center;
    height: 100vh;
}
.login-page .logo img {
    max-height: 10vh;   
}

.login-page .positioner {
    margin-left: calc(50% - (25vw / 2));
    margin-top: 20vh;
    text-align: center;
    width: 25vw;
}

.login-page .spinner {
    margin-top: 5vh;
    margin-bottom: 6vh;
}

.login-page .spinner div:last-child {
    margin-top: 3vh;
}

.login-page .disclaimer {
    text-align: left;
}
.industry-grid {
    border-radius: 5px;
    display: inline-block;
    padding-left: 0vw;    
}

.industry-grid.industry-grid-xl {
    min-height: 15vh;
}

.industry-grid-companies-4 {
    background: radial-gradient(circle at 100% 100%, #fcfcfc 0, #fcfcfc 6px, transparent 3px) 0% 0%/8px 8px no-repeat,
                radial-gradient(circle at 0 100%, #fcfcfc 0, #fcfcfc 6px, transparent 3px) 100% 0%/8px 8px no-repeat,
                radial-gradient(circle at 100% 0, #fcfcfc 0, #fcfcfc 6px, transparent 3px) 0% 100%/8px 8px no-repeat,
                radial-gradient(circle at 0 0, #fcfcfc 0, #fcfcfc 6px, transparent 3px) 100% 100%/8px 8px no-repeat,
                linear-gradient(#fcfcfc 0, #fcfcfc) 50% 50%/calc(100% - 2px) calc(100% - 16px) no-repeat,
                linear-gradient(#fcfcfc 0, #fcfcfc) 50% 50%/calc(100% - 16px) calc(100% - 2px) no-repeat,
                linear-gradient(90deg, #ffd900 0%, #ff2a00 45%, #00b7ffa1 88%);
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;
} 

.industry-grid.industry-grid-0,
.industry-grid.industry-grid-4 {    
    width: 100%;
}

.industry-grid.industry-grid-1,
.industry-grid.industry-grid-2,
.industry-grid.industry-grid-3 {
    width: 10vw;
}

.industry-grid.industry-grid-1-override,
.industry-grid.industry-grid-2-override,
.industry-grid.industry-grid-3-override {
    width: 96%;
}

.industry-grid-header {
    font-weight: 800;
    line-height: 1.8em;
    text-align: center;
}

.industry-grid-companies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.summary-box {
    background-color: #fcfcfc;
    border-radius: 5px;
    cursor: pointer;
    padding-left: 0.3vw;
    padding-bottom: 0.7vh;
    padding-top: 0.7vh;
    width: 100%;
}

.summary-box-title {
    color: #000;
    font-size: 0.75em;
    font-weight:900;
    margin-bottom: 0.5vh;
    text-align: center;
    text-transform: uppercase;    
}

.summary-box-title > span {
    font-size: 0.2em;
    margin-left: 5px;
}

.summary-box-title > div {
    display: inline;
}

.summary-box-title > div:first-child {
    margin-right: 5px;
}

.summary-box:hover .summary-box-title {
    color: #000;
}


.summary-box-value-subtitle {
    color: #555;
    font-size: 0.8em;
    font-weight: 500;
    text-transform: uppercase;
}

.summary-box:hover .summary-box-value-subtitle {
    color: #555;
}

.summary-box-value-number {
    color: #555;
    font-size: 1.75em;
    font-weight: 600;
    text-align: left;
    width: 100%;
}

.summary-box:hover .summary-box-value-number {
    color: #000;    
}

.summary-box-value-number > span {
    color: #777;
    font-size: 0.3em;
    font-weight: 800;
    margin-left: 0.5vw;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.summary-box-companies-container > div {
    display: inline-block;
}

.summary-box-companies-top {
    vertical-align: top;
    width: 85%;
}

.summary-box-companies {
    display: flex;
    flex-flow: row wrap;
    
}

.summary-box-extending {
    color: #333;
    width: 11%;    
}

.industry-grid.industry-grid-0 .summary-box-companies-container {
    display: flex;
    flex-direction: row;
}

.industry-grid.industry-grid-0 .summary-box-companies-container div.selection {
    flex-grow: 1;
    text-align: right;
    line-height: 30px;
    margin-right: 5px;
}
.industry-grid-companies-num-1 {
    border: solid 1px #ffd900ba;
    border-radius: 5px;
}

.industry-grid-companies-num-2 {
    border: solid 1px #ff2a00ac;
    border-radius: 5px;
}

.industry-grid-companies-num-3 {
    border: solid 1px #00b7ffa1;
    border-radius: 5px;
}

.industry-grid-companies-num-4 {
    background: radial-gradient(circle at 100% 100%, #202B37 0, #202B37 6px, transparent 3px) 0% 0%/8px 8px no-repeat,
                radial-gradient(circle at 0 100%, #202B37 0, #202B37 6px, transparent 3px) 100% 0%/8px 8px no-repeat,
                radial-gradient(circle at 100% 0, #202B37 0, #202B37 6px, transparent 3px) 0% 100%/8px 8px no-repeat,
                radial-gradient(circle at 0 0, #202B37 0, #202B37 6px, transparent 3px) 100% 100%/8px 8px no-repeat,
                linear-gradient(#202B37, #202B37) 50% 50%/calc(100% - 2px) calc(100% - 16px) no-repeat,
                linear-gradient(#202B37, #202B37) 50% 50%/calc(100% - 16px) calc(100% - 2px) no-repeat,
                linear-gradient(90deg, #ffd900 0%, #ff2a00 45%, #00b7ffa1 88%);
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;
} 

.industry-grid {    
    display: inline-block;
    padding-left: 0vw;
    margin: 0.2vw;    
}

.industry-grid.industry-grid-num-0,
.industry-grid.industry-grid-num-4 {
    width: 99%;
}

.industry-grid.industry-grid-num-1,
.industry-grid.industry-grid-num-2,
.industry-grid.industry-grid-num-3 {    
    width: 9.7vw;    
}

.industry-grid.industry-grid-num-1-override,
.industry-grid.industry-grid-num-2-override,
.industry-grid.industry-grid-num-3-override {
    width: 96%;
}

.industry-grid-companies-num {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}

.summary-box-num {
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    cursor: pointer;
    padding-left: 0.5vw;
    padding-bottom: 1vh;
    padding-top: 1vh;
    width: 10vw;
}

.summary-box-num > div:first-child {
    width: 85%;
}

.summary-box-num > div:last-child {
    display: flex;
    flex-direction: column;
    width: 15%;
}

.summary-box-num > .summary-box-title {    
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.8em;
    font-weight: 700;
    min-height: 50px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: baseline;
}

.industry-grid.industry-grid-num-0  .summary-box-num,
.industry-grid.industry-grid-num-4  .summary-box-num {
    display: flex;
    width: 100%;
}

.industry-grid.industry-grid-num-0  .summary-box-num  .summary-box-title,
.industry-grid.industry-grid-num-4  .summary-box-num  .summary-box-title  {
    height: 32px;
    line-height: 32px;
    min-height: 20px;
    text-align: center;
    width: 100%;
}

.summary-box-num-value-number {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    color: #fff;
}

.summary-box-num-value-number > div.data-value {
    font-weight: 300;
    font-size: 0.5em;
    text-align: center;
    width: 15px;
}

.industry-grid.industry-grid-num-1  .summary-box-num  .summary-box-value,
.industry-grid.industry-grid-num-2  .summary-box-num  .summary-box-value,
.industry-grid.industry-grid-num-3  .summary-box-num  .summary-box-value {
    width: 15px;
}

.industry-grid.industry-grid-num-0  .summary-box-num  .summary-box-value .summary-box-num-value-number,
.industry-grid.industry-grid-num-4  .summary-box-num  .summary-box-value .summary-box-num-value-number {
    align-items: flex-end;    
}

.summary-box-num:hover > .summary-box-title {
    color: #fff;
}

.summary-box-num:hover .summary-box-num-value-subtitle {
    color: #FFFFFF;
}

.summary-box-num-companies {
    display: flex;
    flex-flow: row wrap;
}

.summary-box-num-extending {    
    color: #fff;
    font-size: 0.75em;
    margin-right: 1vw;
    text-align: right;
}

.summary-box-num:hover .summary-box-num-extending {
    color: #FFFFFF;
}
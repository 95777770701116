.page-header {
    background-color: #E4E6Eb;
    width: 100%;
    height: 4vh;
    padding: 10px;
}

.page-header > div {
    vertical-align: top;
    display: inline-block;
}

.page-header > .page-header-title {
    color: #000;
    font-size: 1.75em;
    font-weight: 700;    
    text-transform: uppercase;
    width: 45%;    
}

.page-header > .page-header-title > a {
    color: #000;
    text-decoration: none;    
}

.page-header > .page-header-toggle {
    text-align: right;
    width: 18%;
}

.page-header > .page-header-login {
    margin-left: 1vw;
}

.page-header img {
    height: 4vh;
    margin-right: 1em;
}

.page-header-total-companies {
    margin-left: 10px;
    font-size: 0.5em;
    font-weight: 300;
}

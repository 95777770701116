.main-area {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 1vw;
    width: 98vw;
}

.main-area > div.compare-base-category {
    margin-right: 1vw;
    width: 49vw;
}

.header-bar-img {
    cursor: pointer;
}

body td.ant-table-cell {
    font-size: 1.3em;
    font-weight: 500;
}
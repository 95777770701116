.company-box {
    background-color: #fcfcfc;
    margin: 3px;
    max-height: 5vh;
}

.industry-grid-0 .summary-box .company-box {
    margin: 5px;
}

.company-logo {
    max-height: 2vh;
    max-width: 1.5vw;
}

.content-box {
    font-size: 0.9em;
    max-width: 300px;
}

.content-box > .description{
    margin-bottom: 10px;
}


.url-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
}

.url-box > div:first-child {
    margin-right: 5px;
}
.detail-page-container {
    display: block;       
}

.main-category-bar {
    font-size: 1.2em;
    font-weight: 700;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    text-decoration: underline;
    text-transform: uppercase;
}

.main-area-table {    
    margin-top: 2vh;
    margin-left: 1%;
    width: 98%;
}

.antd-table .antd-table-cell-row-hover {
    color: #000 !important;
}

.website-link {
    color: #B0B3B8;
}

.website-link:hover {
    color: #E4E6EB;
}

.company-logo-large {
    height: 30px;
}

body td.ant-table-cell {
    font-size: 1.3em;
    font-weight: 500;
}

tbody td.ant-table-cell:first-child {
    background-color: #fff !important;
    text-align: center;
}

tbody td.ant-table-cell-row-hover {
    cursor: pointer !important;
}
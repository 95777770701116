.landscape-canvas {
    height: 90vh;
    overflow-x: hidden;
    margin-left: 1.5vw;
    margin-top: 1vh;
    width: 94vw;
}

.canvas-headers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.canvas-headers > .canvas-header {
    border: 1px solid black;
    border-radius: 5px;
    color: #fff;
    font-size: 1em;
    font-weight: 800;
    height: 4vh;
    line-height: 4vh;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    width: 30vw;
    
}

.canvas-headers > .canvas-header.canvas-header-icon {
    width: 28.5vw;
    margin-bottom: 1vh;
}

.canvas-headers > .canvas-header.canvas-header-xs {
    width: 30vw;
}

.canvas-headers > .canvas-header.canvas-header-yellow {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(121,98,9,1) 35%, #ffd800 100%);    
    border-radius: 20px;
}

.canvas-headers > .canvas-header.canvas-header-red {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(125,78,68,1) 35%, #ff2c00 100%);
    border-radius: 20px;
}

.canvas-headers > .canvas-header.canvas-header-blue {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(68,109,125,1) 35%, #00b7ff 100%);
    border-radius: 20px;
}

.canvas-headers > .canvas-header.canvas-header-icon.canvas-header.canvas-header-yellow {
    width: 30.5vw;
}

.canvas-headers > .canvas-header.canvas-header-icon.canvas-header.canvas-header-red {
    width: 30.5vw;
}

.canvas-headers > .canvas-header.canvas-header-icon.canvas-header.canvas-header-blue {
    width: 30.5vw;
}

.canvas-headers > .canvas-header.canvas-header-num.canvas-header.canvas-header-yellow {
    width: 31vw;    
}

.canvas-headers > .canvas-header.canvas-header-num.canvas-header.canvas-header-red {
    width: 31vw;    
}

.canvas-headers > .canvas-header.canvas-header-num.canvas-header.canvas-header-blue {
    width: 31vw;    
}

.canvas-body {
    display: flex;
    flex-wrap: wrap;
    width: 98vw;
}

.canvas-full-mile {
    width: 93vw;
}

.canvas-full-mile.mile-icon {
    margin-left: 0px;
}

.canvas-mile, 
.canvas-mile-icon {
    width: 31vw;
    vertical-align: top;
    margin-right: 5px;
}

.canvas-mile-icon {
  width: 31vw;
}

.canvas-mile-icon.canvas-mile-icon-xs {
    width: 31vw;
}

.canvas-mile-buffer {
    margin-top: 1vh;
}

.canvas-mile-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    padding-top: 4px;  

}

.landscape-loading {
    color: #fff;
    height: 80vh;
    line-height: 80vh;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.landscape-loading-caption {
    color: #fff;
    font-size: 3em;
    font-weight: 500;
}